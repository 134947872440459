import BieresListWidget from "../components/BieresListWidget";
import GoBackButton from "../components/GoBackButton";
import "../styles/BieresListPage.css";


import MOCK_P from "../assets/bieres/mockup_P.png";
import MOCK_EV from "../assets/bieres/mockup_EV.png";
import MOCK_OLL from "../assets/bieres/mockup_OLL.png";
import MOCK_BV from "../assets/bieres/mockup_BV.png";
import MOCK_F from "../assets/bieres/mockup_F.png";
import MOCK_DD from "../assets/bieres/mockup_DD.png";
import MOCK_SMASH from "../assets/bieres/mockup_smash.png";
import MOCK_ALORS from "../assets/bieres/mockup_alors.png";

import sendGA4Event from "../components/GA4Function";

function BieresListPage() {
    const bieres = [
    {
        nom: "SMASH... et Bim !",
        image : MOCK_SMASH,
        style : "S.M.A.S.H",
        id: "smash"
    },
    {
        nom: "Ça alors!",
        image : MOCK_ALORS,
        style : "HOPPY LAGER",
        id: "caalors"
    },
    {
        nom: "Et voilà!",
        image : MOCK_EV,
        style : "SESSION IPA",
        id: "etvoila"
    },
    {
        nom: "Dis donc!",
        image : MOCK_DD,
        style : "HOPPY PALE ALE",
        id:"disdonc"
    },
    {
        nom: "Fichtre!",
        image : MOCK_F,
        style : "COLD IPA",
        id:"fichtre"
    },
    {
        nom: "Oh là là!",
        image : MOCK_OLL,
        style : "NEIPA",
        id: "ohlala"
    },
    {
        nom: "Papounet",
        image : MOCK_P,
        style : "SAISON au poivre",
        id:"papounet"
    },{
        nom: "Bon V🛫!",
        image : MOCK_BV,
        style : "IPA Ambrée",
        id:"bonv"
    }
    ];

    sendGA4Event("page biere list", "Visite de page");

    return (
        <div className="page-content-frame">
            <div className="biereslistpage-frame">
                <GoBackButton/>
                <span className="biereslistpage-titre"><h2>Nos bières :</h2></span>
                <div className="biereslistpage-container">
                    {/* <BieresListWidget biere={biere}/>
                    <BieresListWidget biere={biere} />
                    <BieresListWidget biere={biere}/> */}
                    {
                        bieres.map(item => (
                            <BieresListWidget biere={item}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default BieresListPage;