
import "../styles/IbuWidget.css";

//Ce widget sert à gérer l'affichage de l'amértume' d'une bière en lui passant en propriété la valeur ibu 


function IbuWidget(props) {

     const ibuValue = props.ibuvalue;
     const texte_amertume = props.amertume;
    //const ibuValue = "32";

    const ratio = ibuValue/60 *100;
    const positionnement = ratio.toString() + "%";
    //const texte_amertume = "Amerture modérée"

    return (
        <div className="ibuwidget-frame">
            <span className="ibuwidget-texte"> <span style={{fontWeight : "bold", color: "inherit"}}>{ibuValue} IBU</span> (Amertume) </span> 
            <div className="ibuwidget-colorbar-container">
                <div className="ibuwidget-colorbar">
                    <div className="ibuwidget-colorbar-orange" style={{width : positionnement}}></div>
                </div>
                <div className="ibuwidget-colorbar-cell"  style={{borderRight : "2px solid black"}}></div>
                <div className="ibuwidget-colorbar-cell"  style={{borderRight : "2px solid black"}}></div>
                <div className="ibuwidget-colorbar-cell"  style={{borderRight : "2px solid black"}}></div>
                <div className="ibuwidget-colorbar-cell"  style={{borderRight : "2px solid black"}}></div>
                <div className="ibuwidget-colorbar-cell"  style={{}}></div>
                
            </div>
            <span className="ibuwidget-amertume" >{texte_amertume}</span>
        </div> 
    )
}

export default IbuWidget;