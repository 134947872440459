import "../styles/PageContentFrame.css";
import "../styles/DistributeursPage.css";
import GoBackButton from "../components/GoBackButton";
import LigneDistributeurs from "../components/LigneDistributeurs";
import sendGA4Event from "../components/GA4Function";


function DistributeursPage() {

    var list_distributeurs49 = [
    {
        logo : "https://www.vandb.fr/bundles/vandbtheme/vandb_b2c/images/logo.png?v=31a5dc28",
        nom : "V & B Cholet",
        ville : "Cholet"
    },
    {
        logo : "https://www.vandb.fr/bundles/vandbtheme/vandb_b2c/images/logo.png?v=31a5dc28",
        nom : "V & B Cholet Sud",
        ville : "Cholet"
    },
    {
        logo : "https://www.bieres-et-chopes.fr/images/bieres_et_chopes.png",
        nom : "Bière et Chopes Cholet",
        ville : "Cholet"
    },
    {
        logo : "https://static.wixstatic.com/media/a281b2_b9b4ed90148d4595baa40ccfe94c5885~mv2.png/v1/fill/w_230,h_220,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Capture%2520d%25E2%2580%2599e%25CC%2581cran%25202020-0.png]",
        nom : "BetoBib",
        ville : "Cholet"
    },
    {
        logo : "https://www.kartingcholet.fr/images/charte/logo-header.png",
        nom : "MK Racing",
        ville : "Saint-Christophe-du-Bois"
    },
    {
        logo : "https://terres-de-vignes.fr/wp-content/uploads/2023/05/cropped-cropped-logo-terres-de-vignes-255x85.png",
        nom : "Terres de Vignes",
        ville : "La Séguinière"
    },
    {
        logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-6/216758783_10225918388844904_8867371073817089244_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=g36LZhgbutsAX-XMcdB&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfBHNPQUjSoCD5jcoYC2zIZvkxQI9f8ZZpBHQkSnZIwz9w&oe=64FA39AB",
        nom : "La Tourelle du Lac",
        ville : "La Tessouale"
    },
    {
        logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-6/292124963_511554297433078_8577005973165211597_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=-oA9VPreHgoAX-r4raW&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfBfMz1Cch5j9X3O-taRS5fasqKJBoH6B6OkHncOsmTaPQ&oe=64FB3FD0",
        nom : "Chez Augustine, comptoir à fromages",
        ville : "Beaupreau"
    },
    {
        logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-6/302723096_501797341952955_6987313941748584613_n.png?_nc_cat=109&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=owT_eaO3ZhoAX9LZ7uc&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfDOBYVfOAUpBBEdzzdFvgVcjRDbj9lG380E5FZ8Y7bbng&oe=64FA0A86",
        nom : "La Cav' Bécon",
        ville : "Becon les Granits"
    },
    {
        logo : "https://legarageangers.fr/wp-content/uploads/2020/10/LeGarage_logo_white.jpg",
        nom : "Le Garage",
        ville : "Angers"
    },
    {
        logo : "https://www.vandb.fr/bundles/vandbtheme/vandb_b2c/images/logo.png?v=31a5dc28",
        nom : "V&B Mûrs-Erigné",
        ville : "Mûrs-Erigné"
    }
    ]
    

    var list_distributeurs44 = [
        
        {
            logo : "https://www.desepicesamaguise.com/images/charte/logo.png",
            nom : "Des épices à ma guise",
            ville : "Ancenis"
        },{
            logo : "https://chopenco.fr/wp-content/uploads/2018/03/Image2-973x1030.png",
            nom : "Chope'n co",
            ville : "Ancenis"
        },{
            logo : "https://jimdo-storage.freetls.fastly.net/image/6526924/d81fc4a9-b425-4880-86a7-c3c4986c9fa9.jpg?format=pjpg&quality=80,90&auto=webp&disable=upscale&width=160&height=160&trim=0,0,0,0",
            nom : "Au Père Peinard",
            ville : "Orvault"
        },{
            logo : "https://lesbieresetonnantes.fr/cdn/shop/files/logo_Tonio_def_vector_noir_page-0001_x80.jpg?v=1631007787",
            nom : "Les bières étonnantes",
            ville : "Sainte Luce sur Loire"
        },{
            logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-6/308502113_788589359238291_5301166656806798409_n.jpg?stp=dst-jpg_s960x960&_nc_cat=107&ccb=1-7&_nc_sid=52f669&_nc_ohc=0PWr_q6VZR4AX8dZPNb&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfBXMIDN1iTwP70PQ4XYvKSam4BWPYnVK8Unb8ni0grTyA&oe=64FB6463",
            nom : "Le Sur Mesure",
            ville : "Nantes"
        }]

    var list_distributeurs85 = [{
        logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-1/305202425_730714511685447_3107885257073029125_n.png?stp=dst-png_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=754033&_nc_ohc=omeJj8XuG60AX9-rnkz&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfBYMU4LfQc8ePCeM411yRwK7hicxzXVEn14ZfMiu7Rabg&oe=64FA6358",
        nom : "La Bièrgerie d'Henri",
        ville : "Mortagne sur Sèvre"
    },
    {
        logo : "https://upload.wikimedia.org/wikipedia/commons/2/25/Carr%C3%A9_blanc.jpg",
        nom : "La Tannerie",
        ville : "Tiffauges"
    },
    {
        logo : "https://scontent.fcdg2-1.fna.fbcdn.net/v/t39.30808-6/305831612_537704215028326_4782460892086713585_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=1GSz9bjVYncAX_sgVMz&_nc_ht=scontent.fcdg2-1.fna&oh=00_AfAwFGfw_WgubH_N3JUrNLrYagxz5R0Wv4fa7Z_D7QNzog&oe=64FB0170",
        nom : "Le trock'son",
        ville : "Saint-Laurent-sur-Sèvre"
    }]


    list_distributeurs44.sort(function(a, b) {
        var villeA = a.ville.toLowerCase(); // Convertit en minuscules pour ignorer la casse
        var villeB = b.ville.toLowerCase();
        if (villeA < villeB) {
          return -1; // a vient avant b dans l'ordre alphabétique
        }
        if (villeA > villeB) {
          return 1; // b vient avant a dans l'ordre alphabétique
        }
        return 0; // Les deux villes sont égales
      });
    
    list_distributeurs49.sort(function(a, b) {
        var villeA = a.ville.toLowerCase(); // Convertit en minuscules pour ignorer la casse
        var villeB = b.ville.toLowerCase();
        if (villeA < villeB) {
            return -1; // a vient avant b dans l'ordre alphabétique
        }
        if (villeA > villeB) {
            return 1; // b vient avant a dans l'ordre alphabétique
        }
        return 0; // Les deux villes sont égales
    });

    list_distributeurs85.sort(function(a, b) {
        var villeA = a.ville.toLowerCase(); // Convertit en minuscules pour ignorer la casse
        var villeB = b.ville.toLowerCase();
        if (villeA < villeB) {
          return -1; // a vient avant b dans l'ordre alphabétique
        }
        if (villeA > villeB) {
          return 1; // b vient avant a dans l'ordre alphabétique
        }
        return 0; // Les deux villes sont égales
      });


    sendGA4Event("Page liste distributeurs", "Visite de page");

    return(
        <div className="page-content-frame">
            <div className="distribpage-frame">
                <GoBackButton/>
                <span className="distribpage-titre"><h3> Les distributeurs du Maine et Loire (49):</h3></span>
                {
                    list_distributeurs49.map((item) =>(
                        <LigneDistributeurs distributeur={item}/>
                    ))
                }
                
                <span className="distribpage-titre"><h3> Les distributeurs de Loire-Atlantique (44):</h3></span>
                {
                    list_distributeurs44.map((item) =>(
                        <LigneDistributeurs distributeur={item}/>
                    ))
                }

                <span className="distribpage-titre"> <h3>Les distributeurs de Vendée (85):</h3></span>
                {
                    list_distributeurs85.length > 0 ?
                    list_distributeurs85.map((item) =>(
                        <LigneDistributeurs distributeur={item}/>
                    )) :
                    <span className="distribpage-msg-alternatif">Pas encore de distributeur dans ce département</span>
                }

                <br/> <br/> <br/> <br/>
                <span className="distribpage-msg-alternatif" style={{fontStyle:"italic" }}>Vous êtes un professionnel et souhaitez ajouter Sans Façon à votre carte ?<br/> <br/> <a href="mailto:contact@brasseriesansfacon.fr">Contactez nous!</a></span>
                <br/>
            </div>

        </div>
    )
}

export default DistributeursPage;