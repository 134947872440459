

import GoBackButton from "../components/GoBackButton";
import "../styles/ErrorPage.css";

function ErrorPage() {
    return(
        <div className="page-content-frame">
            <div className="errorpage-frame">
                <GoBackButton/>
                <span className="errorpage-titre">🍺 Erreur 404 🍺<br/>🍺 Oh là là ! Vous avez déniché un fût vide  🍺 </span>
                <span className="errorpage-texte">
                Nos brasseurs sont perplexes, la page que vous recherchez semble avoir choisi de se cacher parmi les fûts.
                Ne vous inquiétez pas, prenez une gorgée de patience et réessayez. En attendant, imaginez que vous êtes en train de déguster une délicieuse bière virtuelle ! 🍺
                </span>
            </div>
        </div>
    )
}


export default ErrorPage;