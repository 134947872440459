import "../styles/LigneDistributeurs.css";

function LigneDistributeurs(props) {
    const distributeur = props.distributeur;
    return(
        <div className="lignedistrib-frame">
            <img className="lignedistrib-logo" src={distributeur.logo} alt="logo du distributeur"/>
            <span className="lignedistrib-nom">{distributeur.nom}</span>
            <span className="lignedistrib-ville">{distributeur.ville}</span>
        </div>
    )
}


export default LigneDistributeurs;