
import "../styles/EbcWidget.css";

//Ce widget sert à gérer l'affichage de la couleur d'une bière en lui passant en propriété la valeur EBC 


function EbcWidget(props) {

   

    const EbcValue = props.ebcvalue;
    

    const ratio = (75- EbcValue)/75 *100;
    const positionnement = ratio.toString() + "%";


    return (
        <div className="ebcwidget-frame">
            <span className="ebcwidget-texte"> <span style={{fontWeight : "bold", color: "inherit"}}>{EbcValue} EBC</span> (Couleur) </span> 
            <div className="ebcwidget-colorbar">
                <div className="ebcwidget-colorbar-cell"  style={{backgroundColor : "#361010"}}></div>
                <div className="ebcwidget-colorbar-cell"  style={{backgroundColor : "#4D1212"}}></div>
                <div className="ebcwidget-colorbar-cell"  style={{backgroundColor : "#5F1414"}}></div>
                <div className="ebcwidget-colorbar-cell"  style={{backgroundColor : "#98521F"}}></div>
                <div className="ebcwidget-colorbar-cell"  style={{backgroundColor : "#EAA769"}}></div>
                <div className="ebcwidget-colorbar-cercle" style={{left : positionnement}}></div>
            </div>
        </div>
    )
}

export default EbcWidget;