import { Link } from "react-router-dom";
import "../styles/BieresListWidget.css";

function BieresListWidget(props) {

    const biere = props.biere;

    return (
        
        <div className="biereslistwidget-frame">
            <Link to={"permanente/"+biere.id}>
                <img className="biereslistwidget-img" src={biere.image} alt="biere"/>
                <div className="biereslistwidget-header">
                    <span className="biereslistwidget-header-titre">{biere.nom}</span>
                    <span className="biereslistwidget-header-soustitre">({biere.style})</span>
                    <span className="biereslistwidget-header-infos">Cliquer pour en savoir plus</span>
                </div>
            </Link>
            
        </div>
    )
}

export default BieresListWidget;