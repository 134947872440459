import { useNavigate } from "react-router-dom";
import houblon from "../assets/houblon.jpg";
import "../styles/PageContentFrame.css";



function GoBackButton() {
    const history = useNavigate();
  
    const handleGoBack = () => {
      
      history(-1);
    };
  
    return (
        <img className="page-bouton-retour" src={houblon}onClick={handleGoBack} alt="bouton retour"></img>
    );
  }

  export default GoBackButton;