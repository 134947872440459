import sendGA4Event from "../components/GA4Function";
import GoBackButton from "../components/GoBackButton";
import "../styles/MentionsPage.css";


function MentionsPage() {

    sendGA4Event("Page mentions légales","Visite de page");
    return(
        <div className="page-content-frame">
            <div className="mentionspage-frame">
                <GoBackButton/>
                <h2 className="mentionspage-titre">Mentions Légales</h2>
                <br/><br/>
                <span className="mentionspage-texte"> 
                    <span className="mentionspage-gras">Editeur</span>
                    Christelle GUEGAN <br/>
                    Brasserie Sans Façon <br/>
                    ZI La Bergerie, 16 Rue Ampère, <br/>
                    49280 La Séguinière
                    <br/><br/>
                    <span className="mentionspage-gras">Hébergement</span>
                    Société O2Switch <br/>
                    Chemin des Pardiaux, <br/>
                    63000 Clermont-Ferrand

                    <br/><br/><br/><br/>
                    L'abus d'alcool est dangereux pour la santé à consommer avec modération.

                </span>
            </div>
        </div>
    )   
}

export default MentionsPage;