import { Link } from "react-router-dom";
import "../styles/DistributeursBloc.css";

function DistributeursBloc () {
    return (
        <div className="frame-distrib">
                <div className="distrib-zone-texte">
                    <span className="distrib-titre"><h2>Les distributeurs de Sans-Façon !</h2>  </span> <br/>
                    <span className="distrib-msg">
                    Retrouver ici l’ensemble des points de vente de Sans-Façon pour découvrir le plus proche de chez vous! 
                    </span>
                    <Link to="/distributeurs"><button className="distrib-btn">Voir la liste</button></Link>

                </div>
        </div>
    )
}

export default DistributeursBloc;