import sendGA4Event from "../components/GA4Function";
import GoBackButton from "../components/GoBackButton";
import "../styles/ConfidentialitePage.css";

function ConfidentialitePage() {
    sendGA4Event("Page confidentialite", "Visite de page");
    return(
        <div className="page-content-frame">
            <div className="confidentialitepage-frame">
                <GoBackButton/>

                <h2 className="confidentialitepage-titre">Confidentialité</h2>

                <h3 className="confidentialitepage-soustitre">Utilisation des cookies</h3>
                <span className="confidentialitepage-texte">
                Nous tenons à vous informer que notre site web utilise des cookies pour mesurer l'audience et améliorer votre expérience en ligne. (Service de Google Analytics).
                Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil lorsque vous visitez notre site. Ils nous aident à 
                recueillir des informations anonymes sur la manière dont vous interagissez avec nos pages, ce qui nous permet d'optimiser notre 
                contenu et nos services. Si vous préférez ne pas autoriser l'utilisation de cookies, vous avez la possibilité de les désactiver 
                en ajustant les paramètres de votre navigateur. Veuillez consulter la documentation de votre navigateur pour obtenir des instructions 
                spécifiques sur la gestion des cookies. Cependant, veuillez noter que certaines fonctionnalités de notre site pourraient ne pas fonctionner 
                correctement si les cookies sont désactivés.
                </span>
            </div>
        </div>
    )
}

export default ConfidentialitePage;