import "../styles/HistoireBloc.css";
import photo_brasseuse from "../assets/photo_brasseuse.png";

function HistoireBloc() {
    return(
        <div className="histoire-frame">
            <div className="histoire-backgroundForm"></div>
            <div className="histoire-txt-container">
                <span className="histoire-texte">
                    Notre histoire ?
                </span>
                <span className="histoire-texte"> Et bien elle reste à écrire mais ce qui est sûr c'est qu'elle sera remplie de passion et de plaisir. </span>
                <span className="histoire-texte">Alors est-ce que goûter au fruit de cette aventure vous tente ?</span>
            </div>
            <div className="histoire-photo-container">
                <img className="histoire-photo" src={photo_brasseuse} alt="Christelle Guegan brasseuse"></img>
                <span className="histoire-photo-legende"><h3>Christelle GUEGAN <br/> Gérante et fondatrice de Sans Façon </h3></span>
            </div>
        </div>
    )
}

export default HistoireBloc;