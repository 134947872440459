import ReactGA from "react-ga4";


function sendGA4Event(event_name, event_categorie="custom", event_label="") {
    ReactGA.event({
        category: event_categorie,
        action: event_name,
        label : event_label
    })
}

export default sendGA4Event;