import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage"
import Header from "./components/Header"
import Footer from "./components/Footer";
import './App.css';
import TaproomDescPage from "./pages/TaproomDescPage";
import BoutiqueDescPage from "./pages/BoutiqueDescPage";
import ScrollToTop from "./components/ScrollToTop";
import DistributeursPage from "./pages/DistributeursPage";
import VisitesPage from "./pages/VisitesPage";
import BieresListPage from "./pages/BieresListPage";
import BieresPermaDetailsPage from "./pages/BieresPermaDetailsPage";
import ErrorPage from "./pages/ErrorPage";
import MentionsPage from "./pages/MentionsPage";
import ConfidentialitePage from "./pages/ConfidentialitePage";
import ReactGA from "react-ga4";


function App() {
  ReactGA.initialize("G-Z8N465E68V");

  return (
    <Router>
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/taproom" element={<TaproomDescPage/>} />
        <Route path="/boutique" element={<BoutiqueDescPage/>} />
        <Route path="/distributeurs" element={<DistributeursPage/>} />
        <Route path="/visites" element={<VisitesPage/>} />
        <Route path="/bieres" element={<BieresListPage/>}/> 
        <Route path="*" element={<ErrorPage/>} />
            
        
        <Route path="/bieres/permanente/:beerId" element={<BieresPermaDetailsPage/>} />
        <Route path="/mentions-legales" element={<MentionsPage/>} />
        <Route path="/confidentialite" element={<ConfidentialitePage/>} />

      </Routes>

      <Footer/>
    </Router>
    
    
  );
}

export default App;
