import CreationsBloc from "../components/CreationsBloc";
import DegustationBloc from "../components/DegustationBloc";
import DistributeursBloc from "../components/DistributeursBloc";
import EvenementsBloc from "../components/EvenementsBloc";
// import Footer from "../components/Footer";
// import Header from "../components/Header"
import HistoireBloc from "../components/HistoireBloc";
import RencontrerBloc from "../components/RencontrerBloc";

function HomePage() {

    return(
        <div className="page-content-frame">
            
            <CreationsBloc/>
            <DegustationBloc/>
            <RencontrerBloc/>
            <DistributeursBloc/>
            <EvenementsBloc/>
            <HistoireBloc/>
            
        </div>
    )
}

export default HomePage;