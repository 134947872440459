import "../styles/BoutiqueDescPage.css";
import "../styles/PageContentFrame.css";

import boutique from "../assets/devanture.jpg";

import GoBackButton from "../components/GoBackButton";
import sendGA4Event from "../components/GA4Function";

function BoutiqueDescPage(){
    sendGA4Event("Page description boutique", "Visite de page");
    return(
        <div className="page-content-frame">
            <div className="boutiquedesc-frame">
                
                <GoBackButton/>
                <span className="boutiquedesc-titre"><h2>Notre boutique</h2></span>
                <span className="boutiquedesc-texte-haut">
                Venez nous voir dans le coin boutique de la brasserie! On sera là pour vous aiguiller selon vos préférences 
                ou les accords mets-bières que vous souhaitez faire !
                </span>
                <div className="boutiquedesc-bloc">
                    <img className="boutiquedesc-photo" src={boutique} alt="boutique de la brasserie"></img>
                    <div className="boutiquedesc-horaire-container">
                        <span className="boutiquedesc-horaire-titre"><h3>Les horaires :</h3></span> <br/> 
                        <span className="boutiquedesc-horaire-texte">
                            Jeudi et Vendredi <br/>
                            16h00 - 19h00 <br/>
                            <br/>
                            Samedi<br/>
                            15h00 - 19h
                            <br/>
                            <br/>
                            
                            ZI La Bergerie, 16 Rue Ampère, <br/>
                            49280 La Séguinière, à proximité de Cholet

                    
                        </span>
                    </div>
                </div>
                <span className="boutiquedesc-texte-bas">
                <h3 className="boutiquedesc-clickco-titre">Bonne nouvelle le click & collect est disponible!</h3>    
                Vous pouvez dès à présent passer commande sur la <a href="https://shop.brasseriesansfacon.fr" target="_blank">boutique en ligne</a> et venir récupérer vos bières à la brasserie 
                elles seront déjà toutes prêtes. 
                </span>
            </div>
        </div>
    )
}

export default BoutiqueDescPage;