import "../styles/DegustationBloc.css"
import { Link } from "react-router-dom";
function DegustationBloc () {
    return (
        <div className="frame-degustation">
                <div className="degus-zone-texte">
                    <span className="degus-titre"><h2>Une visite avec dégustation ça vous tente ?  </h2></span> <br/>
                    <span className="degus-msg">
                    La Brasserie Sans Façon vous propose de venir visiter ses locaux pour découvrir le processus de préparation de la bière. <br/>
                    Cette visite sera accompagnée d’une session de dégustation ! 
                    </span>
                    <Link to="/visites"> <button className="degus-btn">En savoir plus</button></Link>

                </div>
        </div>
    )
}

export default DegustationBloc;