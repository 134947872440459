import GoBackButton from "../components/GoBackButton";
import "../styles/PageContentFrame.css";
import "../styles/VisitesPage.css";

import devanture from "../assets/devanture.jpg";
import sendGA4Event from "../components/GA4Function";




function VisitesPage() {
    sendGA4Event("Page visites", "Visite de page");
    
    return (
        <div className="page-content-frame">
            <div className="visitpage-frame">
                <GoBackButton/>
                <span className="visitpage-titre">Visiter la brasserie</span>
                <div className="visitpage-container-texte">
                    <span className="visitpage-texte">
                    Vous êtes-vous déjà demandé comment votre bière préférée est fabriquée ? <br/>
                    Ne cherchez plus ! Joignez-vous à nous pour une visite amusante à la brasserie Sans-Façon. <br/> 
                    Nous vous montrerons les ficelles d’un brassage! Attendez, ça devient encore mieux : une dégustation de nos créations est au programme. <br/>
                    C'est l'occasion parfaite de découvrir de nouvelles saveurs et de passer un bon moment.
                    </span>
                    <img className="visitpage-img" src={devanture} alt="devanture de la brasserie Sans Façon"/>
                </div>
                <span className="visitpage-soustitre">Reservez votre visite</span>
                <div className="visitpage-container-texte">

                    <span className="visitpage-texte" style={{width:"80%"}}>
                    Écrivez-nous à l'adresse <b>contact@brasseriesansfacon.fr</b> ou bien envoyez-nous un message sur instagram <b>@brasserie_sans_facon</b>
                    </span>
                </div>
                
                
            </div>
        </div>
    )
}

export default VisitesPage;