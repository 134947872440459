import "../styles/Header.css";

import logo_sans_facon from "../assets/SansFacon-v4_logo-blanc.png"
import { Link } from "react-router-dom";

function Banner() {

    return (
        <div className="background">
            <Link to="/"><img className="logo" src={logo_sans_facon} alt="logo de la Brasserie Sans Façon" /></Link>
            <span className="titre"><h1>Brasserie Sans Façon</h1></span>
        </div>
    )
}

export default Banner;