import "../styles/Footer.css";
import logo_insta from "../assets/logo_insta.png";
import {Link} from "react-router-dom";
import sendGA4Event from "./GA4Function";


function openInstagram() {
    sendGA4Event("Clic direction instagram", "clic boutton");
    window.open('https://www.instagram.com/brasserie_sans_facon/',"_blank");
}

function Footer() {
    return ( <div className="footer-frame">

        <div className="footer-mentions">
            <span className="footer-mention-texte"> <Link to="/mentions-legales"><span className="footer-mention-lien"> Mentions légales</span></Link> /<Link to="/confidentialite"> <span className="footer-mention-lien">Confidentialité</span></Link> / <span className="footer-mention-lien"><a href="mailto:contact@brasseriesansfacon.fr">Nous contacter</a></span></span>
        </div>
        <div className="footer-instagram" onClick={openInstagram}>
            
                <img className="footer-instagram-logo" src={logo_insta} alt="logo instagram"></img>
                <span className="footer-instagram-texte"> @brasserie_sans_facon</span>
            
            
        </div>
        <span className="footer-adresse">ZI La Bergerie, 16 Rue Ampère,<br/> 49280 La Séguinière, à proximité de Cholet </span>

        <div className="footer-droits">
            <span className="footer-droits-texte">© 2023 Brasserie Sans-Façon. Tous droits réservés</span>
        </div>

        

    </div>)
}

export default Footer;